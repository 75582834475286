import React from "react";
import FacebookImg from "../../assets/socials/Facebook.svg";
import LinkedinImg from "../../assets/socials/Linkedin.svg";
import TwitterImg from "../../assets/socials/Twitter.svg";
import Form from "./Form";
import GradientTitle from "../animatedGradient/animatedGradient";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <div className="contact">
        <div className="contact-text">
          <GradientTitle title="Contact Us" />
          <h2> A Project ? An Idea ?</h2>
          <p>
            You can find us on some networks where we post our creations, you
            can also contact us there to ask your questions about prices and our
            availability
          </p>

          {/* This will be visible on larger devices */}

          <div className="hero-line-horizontal larger-device-view">
            <div className="socials-contact">
              <a
                href="https://www.facebook.com/profile.php?id=100092711974020"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FacebookImg} alt="facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/lexodd-hypernova/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinImg} alt="linkedin" />
              </a>
              <a
                href="https://twitter.com/lexoddhypernova"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterImg} alt="twitter" />
              </a>
            </div>
          </div>
        </div>
        <div className="form-area">
          <Form />
        </div>

        {/* This part is for mobile view */}

        <div className="hero-line-horizontal mobile-view">
          <div className="socials-contact">
            <img src={FacebookImg} alt="facebook" />
            <img src={LinkedinImg} alt="linkedin" />
            <img src={TwitterImg} alt="twitter" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
