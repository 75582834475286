import { useEffect } from 'react';

export default function usePagination() {
    useEffect(() => {
        const handlePagination = () => {
            const offset = window.pageYOffset;
            const windowHeight = window.innerHeight;
            const body = document.body;
            const padding = 0.75;
            const pages = Object.keys(document.querySelectorAll('.page'))
                .filter(section => Number(section) + 1)
                .map(section => {
                    return Number(section) + 1;
                });
            // eslint-disable-next-line 
            pages.map(page => {
                if (offset > windowHeight * (page - 2 + padding)) {
                    body.classList.remove(...body.classList);
                    body.classList.add(`page-${page}`);
                }
            });
        };

        handlePagination();
        window.addEventListener('scroll', handlePagination);

        return () => {
            window.removeEventListener('scroll', handlePagination);
        };
    }, []);
}
