import React from "react";
import "./animatedGradient.css";

const GradientTitle = ({ title }) => {
  return (
    <div className="gradient-title">
      <h1>{title}</h1>
    </div>
  );
};

export default GradientTitle;
