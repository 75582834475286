import React from "react";

const ContactDetails = () => {
  return (
    <div className="contact-details">
      <h2>Contact details: </h2>
      <p>
        <a href="mailto:info@lexodd.com"> mail: info@lexodd.com</a>
      </p>
      <p>
        <a href="tel:919908503290"> call: +91 9908503290</a>
      </p>
    </div>
  );
};

export default ContactDetails;
