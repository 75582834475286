import React from "react";
import Arrow from "../../assets/work/arrow.svg";
import Plane from "../../assets/CarouselImage/Plane.svg";
import PlaneSecondary from "../../assets/CarouselImage/PlaneSecondary.svg";
import WorkCard from "./WorkCard";
import GradientTitle from "../animatedGradient/animatedGradient";
import "./Work.css";

const Work = () => {
  return (
    <div className="work-main">
      <GradientTitle title="Operation" />
      <h1 className="work-main-h1">How We Work</h1>
      <img className="Plane" src={Plane} alt="plane" />
      <img
        className="PlaneSecondary"
        src={PlaneSecondary}
        alt="planesecondary"
      />
      <div className="work-arrow">
        <img src={Arrow} alt="arrow" />
      </div>
      <div className="word-card-main">
        <WorkCard
          no="01"
          title="Wireframing"
          para="We start by creating a skeleton of the mockup to work as best as possible on a pleasant navigation for the user."
        />

        <WorkCard
          no="02"
          title="Design interface"
          para="We then work on the UI interface to provide the best possible experience. This involves the composition of colors and the design of elements."
        />

        <WorkCard
          no="03"
          title="Prototype"
          para="Finally, I create the prototype and animation of my web/mobile application/site elements in order to bring my buttons to life, so all my components are present."
        />
      </div>
      <div className="Blob-3"></div>
    </div>
  );
};

export default Work;
