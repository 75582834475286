import React from "react";
import "./footer.css";

const Footer = () => {
  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  return (
    <div className="footer">
      <p>Copyright © {currentYear} | LEXODD HYPERNOVA PRIVATE LIMITED</p>
    </div>
  );
};

export default Footer;
