import React from "react";
import "./Work.css";

const WorkCard = ({ no, title, para }) => {
  return (
    <div className="work-card">
      <h2>{no}</h2>
      <h1>{title}</h1>
      <p>{para}</p>
    </div>
  );
};

export default WorkCard;
