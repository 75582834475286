import React from "react";
import FacebookImg from "../../assets/socials/Facebook.svg";
import LinkedinImg from "../../assets/socials/Linkedin.svg";
import TwitterImg from "../../assets/socials/Twitter.svg";
import ArrowDown from "../../assets/arrowdown/lottie file.svg";
import Button from "../../utils/buttons/Button";
import GradientTitle from "../animatedGradient/animatedGradient";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero-section">
      <div className="Blob"></div>
      <div className="hero-left-line">
        <div className="socials">
          <a
            href="https://www.facebook.com/profile.php?id=100092711974020"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookImg} alt="facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/lexodd-hypernova/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedinImg} alt="linkedin" />
          </a>
          <a
            href="https://twitter.com/lexoddhypernova"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterImg} alt="twitter" />
          </a>
        </div>
      </div>

      <div className="hero-content">
        <h1 className="title-1">Experience The Future With</h1>
        <GradientTitle title="Lexodd" />
        <h3>Leading The Way in Digital Innovation</h3>

        <a href="#about">
          <Button className="button-1">Explore Now</Button>
        </a>
        <div className="arrowdown">
          <img src={ArrowDown} alt="arrowdown" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
