import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  position: relative;
  top: 140px;
  width: 85%;
  padding: 0 55px;
  background-color: #2b2b32;
  background-color: #2b2b3250;
  border-radius: 20px;
  border-collapse: separate;
  color: #fff;
  margin: 30px 15px;
  font-size: 4em;
`;
