import React from "react";
import Specialities1 from "../../assets/specialities/specialities1.svg";
import Specialities2 from "../../assets/specialities/specialities2.svg";
import Specialities3 from "../../assets/specialities/specialities3.svg";
import Card from "./Card";
import GradientTitle from "../animatedGradient/animatedGradient";
import "./Specialities.css";

const Specialities = () => {
  return (
    <div className="lexodd-specialities">
      <GradientTitle title="Lexodd Specialities" />
      <h1 className="specialities-second-h1">Team Skill Set</h1>
      <div className="Card-group">
        <Card
          image={Specialities1}
          para="Advanced Tech and Design method is a system-level approach combining technical and design elements to create innovative products."
        />
        <Card
          image={Specialities2}
          para="Assisting Clients with troubleshooting, advice and solutions for product/service use. Super fast assistance"
        />
        <Card
          image={Specialities3}
          para="Identify the brand specialities and create a unique identity for the clients which makes the brand stand out"
        />
      </div>
      <div className="Blob-2"></div>
    </div>
  );
};

export default Specialities;
