import Hero from "./components/heroSection/Hero";
import About from "./components/aboutSection/About";
import Navbar from "./components/navBar/Navbar";
import Specialities from "./components/specialities/Specialities";
import Projects from "./components/projects/Projects";
import Work from "./components/HowWeWork/Work";
import Contact from "./components/contactUs/Contact";
import Footer from "./components/footer/Footer";
import usePagination from "./utils/buttons/pagination";
import Fade from "react-reveal/Fade";
import ContactDetails from "./components/contactUs/ContactDetails";
import "./App.css";

function App() {
  usePagination();
  return (
    <div>
      <div className="main">
        <Fade>
          <section className="page" id="hero">
            <Navbar />
            <Hero />
          </section>

          <section className="page" id="about">
            <About />
          </section>

          <section className="page" id="specialities">
            <Specialities />
          </section>

          <section className="page" id="projects">
            <Projects />
            <Work />
          </section>

          <section className="page" id="contact">
            <Contact />
            <ContactDetails />
            <Footer />
          </section>
        </Fade>
      </div>

      <ul id="pagination">
        <li>
          <a href="#hero">{}</a>
        </li>
        <li>
          <a href="#about">{}</a>
        </li>
        <li>
          <a href="#specialities">{}</a>
        </li>
        <li>
          <a href="#projects">{}</a>
        </li>
        <li>
          <a href="#contact">{}</a>
        </li>
      </ul>
      {/* <ul id="pagination">

        <li><a href="#hero">{ }</a></li>
        <li><a href="#page-2">{ }</a></li>
        <li><a href="#specialities">{ }</a></li>
        <li><a href="#projects">{ }</a></li>
        <li><a href="#contact">{ }</a></li>
      </ul> */}
    </div>
  );
}

export default App;
