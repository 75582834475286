import React, { useRef, useState } from "react";
import Send from "../../assets/arrowdown/VectorSend.svg";
import FormMemphis from "../../assets/arrowdown/formmemphis1.svg";
import FormTopMemphis from "../../assets/arrowdown/formtopmemphis.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./form.css";

const Form = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    email: "",
    name: "",
    objective: "",
  });

  const isDisabled = form.name === "" || form.email === "";

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      Email: form.email,
      Name: form.name,
      Objective: form.objective,
    };

    axios
      .post(
        "https://sheet.best/api/sheets/a1a5b81b-8f2a-4393-9cd2-f31eac283d08",
        data
      )
      .then((response) => {
        console.log(response);
        setForm({ email: "", name: "", objective: "" });
      })
      .finally(() => setLoading(false));
    toast.success("Thank you. We will get back to you as soon as possible", {
      style: {
        backgroundColor: "purple",
        color: "white",
      },
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <>
      <div className="top-memphis">
        <img src={FormTopMemphis} alt="formtop" />
        <img src={FormTopMemphis} alt="formtop" />
        <img src={FormTopMemphis} alt="formtop" />
        <img src={FormTopMemphis} alt="formtop" />
      </div>
      <div className="container">
        <div className="header">
          <h2>Contact now</h2>
        </div>
        <form id="form" ref={formRef} className="form" onSubmit={formSubmit}>
          <div className="form-control">
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="E-mail"
              id="email"
            />
            <i className="fas fa-check-circle"></i>
            <i className="fas fa-exclamation-circle"></i>
            <small>Error message</small>
          </div>
          <div className="form-control">
            <input
              pattern="[A-Za-z ]+"
              maxLength="50"
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="Name"
              id="username"
            />
            <i className="fas fa-check-circle"></i>
            <i className="fas fa-exclamation-circle"></i>
            <small>Error message</small>
          </div>

          <div className="form-control objective">
            <input
              type="text"
              name="objective"
              value={form.objective}
              onChange={handleChange}
              placeholder="Objective"
              id="Objective"
            />
            <i className="fas fa-check-circle"></i>
            <i className="fas fa-exclamation-circle"></i>
            <small>Error message</small>
          </div>

          <button type="submit" disabled={isDisabled}>
            {loading ? "Submitting" : "Submit"}
            <img className="sendvector" src={Send} alt="send" />
          </button>
        </form>
      </div>
      <img className="form-memphis" src={FormMemphis} alt="memphis2" />
      <ToastContainer />
    </>
  );
};

export default Form;
