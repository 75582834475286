import React, { useState, useEffect } from "react";
import FacebookImg from "../../assets/socials/Facebook.svg";
import LinkedinImg from "../../assets/socials/Linkedin.svg";
import TwitterImg from "../../assets/socials/Twitter.svg";
import "./Hamburger.css";

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.position = isOpen ? "" : "fixed";
  };

  return (
    <div className="hamburger-view">
      <div className={`menu-bg ${isOpen ? "fs" : ""}`}></div>
      <div className={`menu-burger ${isOpen ? "fs" : ""}`} onClick={toggleMenu}>
        {isOpen ? "✕" : "☰"}
      </div>

      <div className={`menu-items ${isOpen ? "fs" : ""}`}>
        <div className="blob-ham"></div>
        <div className="menu-element">
          <a href="#hero" onClick={toggleMenu}>
            HOME
          </a>
        </div>
        <div className="menu-element">
          <a href="#about" onClick={toggleMenu}>
            ABOUT US
          </a>
        </div>
        <div className="menu-element">
          <a href="#projects" onClick={toggleMenu}>
            PROJECTS
          </a>
        </div>
        <div className="menu-element">
          <a href="#contact" onClick={toggleMenu}>
            CONTACT US
          </a>
        </div>
        <div className="blob-ham"></div>
        <div className="hamburger-line">
          <div className="socials-contact">
            <a
              href="https://www.facebook.com/profile.php?id=100092711974020"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FacebookImg} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedinImg} alt="linkedin" />
            </a>
            <a
              href="https://twitter.com/lexoddhypernova"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TwitterImg} alt="twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HamburgerMenu;
