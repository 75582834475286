import React from "react";
import Logo from "../../assets/logo/lexoddLogo.svg";
import "./navbar.css";
import HamburgerMenu from "../Hamburger/Hamburger";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <img src={Logo} alt="logo" />
      </div>
      <HamburgerMenu />
    </>
  );
};

export default Navbar;
