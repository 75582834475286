import React from "react";
import Carousel from "react-elastic-carousel";
import Item from "./item";
import Borabay from "../../assets/CarouselImage/Borabay.svg";
import Dripp from "../../assets/CarouselImage/Drip.svg";
import Lotus from "../../assets/CarouselImage/LotusSvg.svg";
import SquareMemphis from "../../assets/arrowdown/formmemphis1.svg";
import BottomMemphis from "../../assets/arrowdown/menphis 1.svg";
// import Rectangle from "./Rectangle";

import GradientTitle from "../animatedGradient/animatedGradient";
import "./Projects.css";

const Projects = () => {
  return (
    <div className="Projects-main">
      <GradientTitle title="Lexodd Projects" />
      <img className="carouselmemphis1" src={SquareMemphis} alt=" memphis " />
      <div className="carousel">
        <Carousel
          itemsToShow={1}
          // renderPagination={({ pages, activePage, onClick }) => {
          //   return (
          //     <div>
          //       {pages.map((page) => {
          //         const isActivePage = activePage === page;
          //         return (
          //           <Rectangle
          //             key={page}
          //             onClick={() => onClick(page)}
          //             active={isActivePage}
          //           />
          //         );
          //       })}
          //     </div>
          //   );
          // }}
        >
          <Item>
            <div>
              <div className="content__carousel">
                <h2>Borabay</h2>
                <div>
                  <img className="carousel-img" src={Borabay} alt="Borabay" />
                </div>
                {/* <h5>View More &gt;</h5> */}
              </div>
              <p>
                Borabay are a team of professionals, who understand the matrix
                behind beauty and bring out the best of it for you.At Borabay, a
                variety of services and customer experience is our priority.
                From a range of hair styling services, beauty treatments, and
                cosmetic advances to rejuvenation rituals. We are a one-stop
                destination for renewal & restoration.
              </p>
            </div>
          </Item>
          <Item>
            <div>
              <div className="content__carousel">
                <h2>Dripp</h2>
                <div>
                  <img className="carousel-img" src={Dripp} alt="Dripp" />
                </div>
                {/* <h5>View More &gt;</h5> */}
              </div>
              <p>
                Custom clothing is the future of fashion, thanks to technology
                like print on demand which allows customers to design and
                visualize their own unique clothing. It's a great way to express
                individuality and showcase personal style. Custom clothing is
                not only a way to stand out from the crowd but also it gives the
                customer the freedom to be creative, and it is an opportunity to
                be expressive and to showcase one's individuality.
              </p>
            </div>
          </Item>
          <Item>
            <div>
              <div className="content__carousel">
                <h2>Lotus</h2>
                <div>
                  <img className="carousel-img" src={Lotus} alt="Lotus" />
                </div>
                {/* <h5>View More &gt;</h5> */}
              </div>
              <div className="Lotus_para">
                <p>
                  Lotus Company specializes in handcrafted soaps and candles,
                  crafted with the utmost care and attention to detail. Our
                  selection of luxurious soaps and candles are designed to
                  provide a sense of relaxation and calm, while also providing a
                  unique experience with their fragrances and colors. We are
                  committed to using only the highest-quality ingredients, and
                  all our products are cruelty-free and made with natural
                  ingredients. With our beautiful, handcrafted products, we
                  strive to create a luxurious, spa-like experience for our
                  customers.
                </p>
              </div>
            </div>
          </Item>
        </Carousel>
      </div>
      <img className="BottomMemphis" src={BottomMemphis} alt="mem" />
    </div>
  );
};

export default Projects;
