import React from "react";
import AboutImg from "../../assets/about/About.png";
import Memphis from "../../assets/arrowdown/menphis 1.svg";
import GradientTitle from "../animatedGradient/animatedGradient";
import "./About.css";

const About = () => {
  return (
    <div className="About-section">
      <GradientTitle title="About Us" />
      <div className="about-section-insider">
        <p>
          <span id="content1">
            {" "}
            At Lexodd Hypernova, located in Hyderabad, India, we specialize in
            providing cutting-edge technology solutions and services to our
            clients. Our team of experienced engineers and experts are dedicated
            to creating innovative solutions that enable businesses to
            streamline their operations and make data-driven decisions.
          </span>
          <span id="content2">
            {" "}
            We use the latest tools and techniques to create responsive and
            adaptive designs that work seamlessly across devices, and our team
            of skilled developers are proficient in developing applications that
            are optimized for speed, performance, and security. Moreover, we are
            constantly working on revolutionary projects that are set to change
            the future of mankind.
          </span>
        </p>

        <div>
          <div className="img-wrapper">
            <div className="image-wrapper"></div>
            <img className="about-img1" src={AboutImg} alt="about" />
            <img className="about-img2" src={Memphis} alt="mem" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
