import React from "react";
import "./Specialities.css";

const Card = ({ para, image }) => {
  return (
    <div className="Card">
      <img src={image} alt={image} />
      <p>{para}</p>
    </div>
  );
};

export default Card;
